
import Vue from "vue";
import KForm from "@/components/KForm.vue";
import axios, { AxiosResponse } from "axios";
import eventBus from "@/application/eventBus";
import { mapGetters } from "vuex";
import { DeliveryStatusCode, UnitLoad } from "@/modules/delivery/enums";
import DeliveryFormDetails from "@/modules/delivery/views/form/DeliveryFormDetails.vue";
import DeliveryFormProducts from "@/modules/delivery/views/form/DeliveryFormProducts.vue";
import {
  deliveryDestroy,
  deliveryShow,
  sendById,
} from "@/modules/delivery/api/delivery";
import { DeliveryShowResource } from "@/modules/delivery/types";

interface ComponentData {
  isLoading: boolean;
  values: Partial<DeliveryShowResource>;
  isLoadingSave: boolean;
  isFormDirty: boolean;
}

export default Vue.extend({
  name: "DeliveryForm",
  components: {
    DeliveryFormProducts,
    DeliveryFormDetails,
    KForm,
  },
  data: (): ComponentData => ({
    isLoading: true,
    values: {
      enteredBy: {
        name: "",
        email: "",
      },
      items: [],
    },
    isFormDirty: false,
    isLoadingSave: false,
  }),
  computed: {
    isNewDelivery() {
      if (this.values?.statusCode == null) return true;
      return this.values?.statusCode === DeliveryStatusCode.New;
    },
    ...mapGetters("authorisation", ["client", "isClient", "profile"]),
    clientId(): number {
      return parseInt(this.$route.params.clientId as string);
    },
  },
  watch: {
    "$route.params.deliveryId": {
      immediate: true,
      async handler(id: number): Promise<void> {
        if (!id) {
          this.isLoading = false;
          return;
        }
        try {
          this.isLoading = true;
          const response = await deliveryShow({
            deliveryId: id,
            clientId: this.clientId,
          });

          this.resetDelivery();
          this.values = {
            ...this.values,
            ...response.data.data,
          };
          this.$nextTick(() => (this.isFormDirty = false));
        } catch (error: AxiosError | unknown) {
          if (axios.isAxiosError(error) && error.response?.status === 404) {
            this.$router.push({ name: "notFound" });
            return;
          }
          throw error;
        }

        this.isLoading = false;
      },
    },
    values: {
      deep: true,
      handler(): void {
        this.isFormDirty = true;
      },
    },
    "client.id": {
      handler(newValue: number, oldValue: number): void {
        if (!oldValue) return;
        this.redirectToOverview();
      },
    },
  },
  created() {
    this.resetDelivery();

    this.values.enteredBy = {
      name: this.profile.name,
      email: this.profile.email,
    };
  },
  methods: {
    resetDelivery() {
      this.values = {
        reference: "",
        plannedDate: "",
        unitLoad: undefined,
        unitLoadInfo: undefined,
        enteredBy: {
          name: "",
          email: "",
        },
        items: [],
      };
    },
    onCancelClick() {
      this.$router.go(-1);
    },
    send(): void | Promise<AxiosResponse<DeliveryShowResource>> {
      if (!this.values) return;

      if (this.values.unitLoad === UnitLoad.Colli) {
        this.values.unitLoadInfo = undefined;
      }

      this.isFormDirty = false;

      return sendById({
        delivery: this.values,
        deliveryId: parseInt(this.$route.params.deliveryId),
        clientId: this.clientId,
      });
    },
    async confirmBeforeRemoveDelivery() {
      eventBus.$emit("confirm", {
        title: this.$t("delivery.messages.confirmationDeleteTitle"),
        body: this.$t("delivery.messages.confirmationDeleteBody"),
        cancelText: this.$t("global.no"),
        confirmText: this.$t("global.yes"),
        confirmCallback: this.removeDelivery,
      });
    },
    async removeDelivery() {
      await deliveryDestroy({
        clientId: this.clientId,
        deliveryId: parseInt(this.$route.params.deliveryId),
      });
      eventBus.$emit(
        "snackbar",
        this.$t("delivery.messages.destroySuccessful")
      );
      this.redirectToOverview();
    },
    handleSuccess() {
      eventBus.$emit(
        "snackbar",
        this.$t("delivery.messages.sendDeliverySuccess")
      );
      this.redirectToOverview();
    },
    redirectToOverview() {
      this.$router.push({
        name: "delivery.index",
      });
    },
  },
});

import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";
import { destroy, get, put } from "@/application/api";
import { DeliveryShowResource } from "@/modules/delivery/types";

export interface DeliveryIndexItem {
  id: number;
  clientName: string;
  clientId: number;
  reference: string;
  plannedDate: string;
  unitLoad: string;
  statusName: string;
  statusCode: string | number | null;
  errors?: { message: string; errors: Record<string, string[]> };
}

export const clientDeliveryIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<DeliveryIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/delivery`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};

export const deliveryShow = ({
  clientId,
  deliveryId,
}: {
  clientId: number;
  deliveryId: number;
}): Promise<
  AxiosResponse<{
    data: DeliveryShowResource;
  }>
> => get(`client/${clientId}/delivery/${deliveryId}`);

export const deliveryDestroy = ({
  clientId,
  deliveryId,
}: {
  clientId: number;
  deliveryId: number;
}) => destroy(`client/${clientId}/delivery/${deliveryId}`);

export const sendById = ({
  deliveryId,
  delivery,
  clientId,
}: {
  delivery: Partial<DeliveryShowResource>;
  deliveryId: number;
  clientId: number;
}): Promise<AxiosResponse> => {
  return put(`client/${clientId}/delivery/${deliveryId}`, delivery);
};

export const purchaseOrderStatusAutocomplete = (
  clientId: number,
  data: PaginatedRequest
): Promise<AxiosResponse<PaginatedResponse<{ id: number; name: string }[]>>> =>
  getPaginated(`client/${clientId}/purchase-order-status/autocomplete`, data);
